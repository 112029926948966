body {
  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;
}
.box {
  width: 300px;
  h1 {
    font-size: 20px;
    margin: 0 0 1rem 0;
  }
}